<template>
    <div>
        <b-card
            bg-variant="danger"
            text-variant="white"
            header="Shrnutí objednávky"
            class="text-center"
            v-if="platba_checked == true && platba_error != null"
        >
            <div class="w-100 text-center">
                <br><br><br>
                <h2>
                    Pravděpodobně došlo k porušení integrity dat.
                </h2>
                <br>
                <p>
                    <em>
                        Příčina: {{ platba_error.message }}
                    </em>
                </p>
                <br><br>
                <p>
                    Kontaktujte prosím podporu.
                    <br>
                    ...
                </p>
                <b-button
                    variant="primary"
                    class="mb-2 btn-sm-block"
                    :to="{ name: 'default-view', params: { }}"
                >
                    {{ $t("Zpět") }}
                </b-button>
                <br><br><br>
            </div>
        </b-card>

        <b-card
            bg-variant="light"
            text-variant="black"
            header="Shrnutí objednávky"
            class="text-center"
            v-if="platba_error == null && platba_state_unfinished == true"
        >
            <div class="w-100 text-center">
                <h2>
                    Vaše platba kartou nebo online převodem nebyla dokončena.
                </h2>
                <br>
                <p>
                    Máte možnost zaplatit platbu bankovním převodem.
                </p>
                <br>
                <h3>Souhrné informace k objednávce</h3>
                <p>
                    Variabilní symbol: {{ objednavka.variabilni_symbol }}
                </p>
                <br>
                <p>
                    Pokud chcete zaplatit platbu bankovním převodem, klikněte zde
                </p>
                <b-button
                    variant="primary"
                    class="mb-2 btn-sm-block"
                    @click="changeOrderToBankTransfer"
                >
                    Zaplatit bankovním převodem
                </b-button>
                <br><br><br>
                <p>
                    Pokud chcete pokračovat další objednávkou, klikněte zde
                </p>
                <b-button
                    variant="primary"
                    class="mb-2 btn-sm-block"
                    @click="changeOrderToCancelled"
                >
                    Další objednávka
                </b-button>
                <br><br>
                <p>
                    Rancherské speciality z Jestřebic
                </p>
            </div>

            <!--<div class="w-100 text-center">
                <br><br><br>
                <h2>
                    Vaše objednávka čeká na zaplacení.
                </h2>
                <br><br>
                <p>
                    Vyčkejte prosím chvíli, zjišťujeme, zda byla objednávka zaplacena.
                    <br><br>
                    Ověření platby: {{ check_payment_counter }} / {{ check_payment_max - 1 }}
                </p>
                <br><br><br>
            </div>-->
        </b-card>

        <!--<b-card
            bg-variant="light"
            text-variant="black"
            header="Shrnutí objednávky"
            class="text-center"
            v-if="platba_state_unfinished == true"
        >
            <div class="w-100 text-center">
                <br><br><br>
                <h2>
                    Vaše objednávka nebyla dokončena.
                </h2>
                <br><br>
                <p>
                    Co nejdříve Vás budeme kontaktovat ohledně jejího zdárného dokončení.
                </p>
                <b-button
                    variant="primary"
                    class="mb-2 btn-sm-block"
                    :to="{ name: 'default-view', query: { path: path }}"
                >
                    {{ $t("Zpět") }}
                </b-button>
                <br><br><br>
            </div>
        </b-card>-->
    </div>
</template>

<script>
import * as constants from '@/store/constants.js'
import { state, mutations } from '@/store/index.js'

export default {
    components: { },

    computed: {
        platba_checked( ) {
            return state.platba_checked
        },

        platba_error( ) {
            return state.platba_error
        },

        platba_state_unfinished( ) {
            return state.platba_state_unfinished
        }
    },

    data( ) {
        return {
            check_payment_counter: 1,
            check_payment_max: 6,
            objednavka: {
                variabilni_symbol: null
            },
            path: null
        }
    },

    mounted( ) {
        let params = this.$route.query
        //console.log("PendingView.params", params)

        this.checkPayment({
            objednavka_id: params.refId,
            platba_id: params.id,
            stav_platby_id: constants.STAV_PLATBY_CEKAJICI
        })

        mutations.changePaymentToUnfinished({
            objednavka_id: params.refId,
            platba_id: params.id,
            stav_platby_id: constants.STAV_PLATBY_CEKAJICI
        })
        .then((response) => {
            this.path = response.path
        })
        .catch(errors => {
            mutations.processPaymentDataErrors(errors)
        })
    },

    methods: {
        changeOrderToBankTransfer( ) {
            //console.log("changeOrderToBankTransfer")

            let params = this.$route.query

            mutations.changeOrderToBankTransfer({
                objednavka_id: params.refId,
                platba_id: params.id
            }).then(response => {
                //console.log(response)
                this.$router.push({
                        name: 'payment-transfer',
                        query: {
                            path: response.path,
                            refId: response.refId
                        }
                    })
                })
            .catch(errors => {
                mutations.processOrderDataErrors(errors)
            })
        },

        changeOrderToCancelled( ) {
            //console.log("changeOrderToCancelled")

            let params = this.$route.query

            mutations.changeOrderToCancelled({
                objednavka_id: params.refId,
                platba_id: params.id
            }).then(response => {
                //console.log(response)
                this.$router.push({
                    name: 'default-view',
                    query: { path: response.path }
                })
            })
            .catch(errors => {
                mutations.processOrderDataErrors(errors)
            })
        },

        checkPayment(platbaData) {
            //let self = this

            mutations.checkPayment(platbaData)
                .then((response) => {
                    //console.log("PendingView.checkPayment( )", response)

                    this.objednavka = response.objednavka

                    this.path = response.path

                    /*if (response.stav_platby_id == constants.STAV_PLATBY_ZAPLACENA) {
                        this.$router.push({
                            name: "payment-paid",
                            query: {
                                refId: platbaData.objednavka_id,
                                id: platbaData.platba_id
                            }
                        })
                    } else if (response.stav_platby_id == constants.STAV_PLATBY_ZRUSENA) {
                        this.$router.push({
                            name: "payment-cancelled",
                            query: {
                                refId: platbaData.objednavka_id,
                                id: platbaData.platba_id
                            }
                        })
                    } else {
                        // Test na max. počet kontrol platby
                        if (self.check_payment_counter + 1 < self.check_payment_max) {
                            setTimeout(function( ) {
                                self.check_payment_counter += 1
                                self.checkPayment(platbaData)
                            }, 2000)
                        } else {
                            mutations.changePaymentToUnfinished(platbaData)
                                .then((response) => {
                                    this.path = response.path
                                })
                                .catch(errors => {
                                    mutations.processPaymentDataErrors(errors)
                                })
                        }
                    }*/
                })
                .catch(errors => {
                    mutations.processPaymentDataErrors(errors)
                })
        }
    }
}
</script>

<style scoped>
</style>